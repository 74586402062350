import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint } from '../../configs/utilities'
import ImageTextComponent from '../../components/sections/ImageTextComponent'
import { useWindowSize } from '../../configs/getGlobalWindowSize'

const Sections = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allLocalitySectionsJson {
        edges {
          node {
            reverse
            title
            text01
            text02
            showButton
            button {
              external
              path
              buttonText
            }
            imageXl {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
            imageSm {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `)

  const sections = data.allLocalitySectionsJson.edges
  const currentWidth = useWindowSize().width
  return (
    <SectionWrapper>
      <Wrapper>
        {sections.map((item, index) => {
          return (
            <ImageTextComponent
              key={index}
              reverse={item.node.reverse}
              title={intl.formatMessage({ id: item.node.title })}
              image={
                currentWidth > 768
                  ? item.node.imageXl.childImageSharp.gatsbyImageData
                  : item.node.imageSm.childImageSharp.gatsbyImageData
              }
              text01={intl.formatMessage({ id: item.node.text01 })}
              text02={intl.formatMessage({ id: item.node.text02 })}
              showButton={item.node.showButton}
              external={item.node.button !== null ? item.node.button.external : null}
              to={item.node.button !== null ? item.node.button.path : null}
              buttonText={item.node.button !== null ? intl.formatMessage({ id: item.node.button.buttonText }) : null}
            />
          )
        })}
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(Sections)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoint.xl}) {
    row-gap: 100px;
  }
`
