import React from 'react'
import styled from 'styled-components'
import HeroSection from '../sections/locality/HeroSection'
import Sections from '../sections/locality/Sections'
import Layout from '../components/layout/Layout'

const Locality = () => {
  return (
    <Layout pageName="locality">
      <Wrapper>
        <HeroSection />
        <Sections />
      </Wrapper>
    </Layout>
  )
}

export default Locality

const Wrapper = styled.div``