import React from 'react'
import HeroComponent from '../../components/sections/HeroComponent'
import heroSm from '../../assets/locality/hero/heroSm.webp'
import heroMd from '../../assets/locality/hero/heroMd.webp'
import heroXl from '../../assets/locality/hero/heroXl.webp'
import { injectIntl } from 'gatsby-plugin-react-intl'

const Hero = ({intl})  => {
  return (
      <HeroComponent 
      sectionTitle={intl.formatMessage({ id: 'locality.hero.sectionTitle' })}
      pageTitle={intl.formatMessage({ id: 'locality.hero.pageTitle' })}
      heroSm={heroSm}
      heroMd={heroMd}
      heroXl={heroXl}
      />
  )
}

export default injectIntl(Hero)
